import React, { useState, useEffect, useRef } from "react";
import loadable from "@loadable/component";
import { graphql, navigate } from "gatsby";
import { isMobileOnly } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import {
  PageWrapperMobile,
  ContentWrapperMobile,
  TitleWrapperMobile,
  MobileNavigationBar,
  LogoMobile,
  NavigationBar,
  TitleMobile,
  DescriptionWrapperMobile,
  DescriptionWithNumberWrapperMobile,
  NumberMobile,
  DescriptionMobile,
  BackgroundWrapperMobile,
  BlueRectangleMobile,
  TransitionWrapper,
  TransitionBlock,
  PageWrapper,
  StartingPageWrapper,
  ContentWrapper,
  TitleWrapper,
  Title,
  DescriptionWrapper,
  DescriptionWithNumberWrapper,
  Number,
  Description,
} from "./style";
import { PageTitle } from "../../common/PageTitle";
import Navigation, { NavigationRefMethods } from "../../common/Navigation";
import Scroll, { PageChange, ScrollRefMethods } from "../../common/Scroll";
import Loader from "../../common/Loader";
import { LocationProp, PageThemeType } from "../../../utils/types";
import { device } from "../../../utils/breakpoints";
import { numberToFixedLength } from "../../../utils/common";
import { fixConjunctions } from "../../../utils/dropTheWord";

const loadableOptions = {
  fallback: <Loader fullPage width={100} height={100} />,
};
const CivileoMobile = loadable(
  () => import("./components/Civileo/Mobile"),
  loadableOptions
);
const TeamMobile = loadable(
  () => import("./components/Team/Mobile"),
  loadableOptions
);
const AITVStreamMobile = loadable(
  () => import("./components/Apps/AppAITVStream/Mobile"),
  loadableOptions
);
const CivileoDesktop = loadable(
  () => import("./components/Civileo/Desktop"),
  loadableOptions
);
const TeamDesktop = loadable(
  () => import("./components/Team/Desktop"),
  loadableOptions
);
const AITVStreamDesktop = loadable(
  () => import("./components/Apps/AppAITVStream/Desktop"),
  loadableOptions
);
const pageThemes = [["white", "blue"], "blue", "blue"] as PageThemeType[];

const navigationThemes = ["default", "default", "default"] as PageThemeType[];

const transitionBlocks = [...Array(20)].map((_, index) => (
  <TransitionBlock key={index} className="whiteBlock" />
));

const RnDPage: React.FC<LocationProp> = ({ location }) => {
  const [, setLoading] = useState<boolean>(true);
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState<PageChange>();
  const [isMobile, setIsMobile] = useState<boolean>(isMobileOnly);
  const [page, setPage] = useState<number>(0);

  const navRef = useRef<NavigationRefMethods>(null);
  const scrollRef = useRef<ScrollRefMethods>(null);
  const isSmallWindow = useMediaQuery({ query: device.mobile });

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setIsMobile(isMobileOnly);
  }, [isMobileOnly]);

  const { t } = useTranslation("rnd");
  const descriptions = (t(`rnd_descriptions`) as unknown) as string[];

  const renderBlueRectanglesMobile = () => {
    return (
      <BackgroundWrapperMobile>
        <BlueRectangleMobile row="1" column="4" />
        <BlueRectangleMobile row="2" column="2 / span 2" />
        <BlueRectangleMobile row="3" column="1" />
        <BlueRectangleMobile row="3" column="4" />
        <BlueRectangleMobile row="4" column="2" />
        <BlueRectangleMobile row="4" column="4" />
        <BlueRectangleMobile row="5" column="3" />
        <BlueRectangleMobile row="6" column="4" />
      </BackgroundWrapperMobile>
    );
  };

  const renderDescriptionsMobile = () =>
    descriptions.map((description, index) => (
      <DescriptionWithNumberWrapperMobile key={index}>
        <NumberMobile>
          {numberToFixedLength({ number: index + 1, length: 2 })}
        </NumberMobile>
        <DescriptionMobile
          dangerouslySetInnerHTML={{
            __html: fixConjunctions(description),
          }}
        />
      </DescriptionWithNumberWrapperMobile>
    ));

    
  const goToMainPage = () => {
    setPage(0);
    navigate('/rnd');
  };

  return isMobile || isSmallWindow ? (
    <>
      {/* <NavigationBar>
        <LogoMobile onClick={goToMainPage} /> */}
        {/* <MobileNavigationBar /> */}
        <Navigation
          ref={navRef}
          setModalState={setModalState}
          setPageChange={setPageChange}
          navigationThemes={navigationThemes}
          location={location}
          theme="light"
          setPage={setPage}
        />
      {/* </NavigationBar> */}
      <Scroll
        ref={scrollRef}
        isModalOpen={isModalOpen}
        pageThemes={pageThemes}
        location={location}
        pageChange={pageChange}
        setPageChange={setPageChange}
        transitionTimeoutMS={600}
        page={page}
        setPage={setPage}
      >
        <PageWrapperMobile animation={pageChange}>
          <PageTitle subtitle={t(`rnd_page_title`)} />
          <TransitionWrapper>{transitionBlocks}</TransitionWrapper>
          {/* <PageWrapperMobile animation={pageChange}> */}
          <ContentWrapperMobile>
            <TitleWrapperMobile>
              <TitleMobile color="#013CFF">R</TitleMobile>
              <TitleMobile>&</TitleMobile>
              <TitleMobile color="#013CFF">D</TitleMobile>
            </TitleWrapperMobile>
            <DescriptionWrapperMobile>
              {renderBlueRectanglesMobile()}
              {renderDescriptionsMobile()}
            </DescriptionWrapperMobile>
          </ContentWrapperMobile>
          {/* </PageWrapperMobile> */}
        </PageWrapperMobile>
        <TeamMobile pageChange={pageChange} />
        <CivileoMobile
          pageThemeChange={navRef?.current?.setPageTheme}
          pageChange={pageChange}
        />
        <AITVStreamMobile
          pageThemeChange={navRef?.current?.setPageTheme}
          pageChange={pageChange}
        />
      </Scroll>
    </>
  ) : (
    <>
      <Navigation
        ref={navRef}
        setModalState={setModalState}
        setPageChange={setPageChange}
        navigationThemes={navigationThemes}
        location={location}
        theme="light"
        setPage={setPage}
      />
      <Scroll
        ref={scrollRef}
        isModalOpen={isModalOpen}
        pageThemes={pageThemes}
        location={location}
        pageChange={pageChange}
        setPageChange={setPageChange}
        transitionTimeoutMS={600}
        page={page}
        setPage={setPage}
      >
        <PageWrapper animation={pageChange}>
          <TransitionWrapper>{transitionBlocks}</TransitionWrapper>
          <PageTitle subtitle={t(`rnd_page_title`)} />
          <>
            <StartingPageWrapper animation={pageChange}>
              <ContentWrapper>
                <TitleWrapper>
                  <Title color="#013CFF">R</Title>
                  <Title>&</Title>
                  <Title color="#013CFF">D</Title>
                </TitleWrapper>
                <DescriptionWrapper>
                  {descriptions.map((description, index) => (
                    <DescriptionWithNumberWrapper key={index}>
                      <Number>
                        {numberToFixedLength({ number: index + 1, length: 2 })}
                      </Number>
                      <Description
                        dangerouslySetInnerHTML={{
                          __html: fixConjunctions(description),
                        }}
                      />
                    </DescriptionWithNumberWrapper>
                  ))}
                </DescriptionWrapper>
              </ContentWrapper>
            </StartingPageWrapper>
          </>
        </PageWrapper>
        <TeamDesktop pageChange={pageChange} />
        <CivileoDesktop
          navPageThemeChange={navRef?.current?.setPageTheme}
          scrollPageThemeChange={scrollRef?.current?.setPageTheme}
          pageChange={pageChange}
        />
        <AITVStreamDesktop
          scrollPageThemeChange={scrollRef?.current?.setPageTheme}
          pageChange={pageChange}
        />
      </Scroll>
    </>
  );
};

const imagesQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "navigationBackground/R&D" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default RnDPage;
