import React, { useEffect } from "react";

import { Wrapper } from "../assets/styles/pageStyle";
import RnDPage from "../components/pages/R&D";
import { LocationProp } from "../utils/types";

const RnD: React.FC<LocationProp> = ({ location }) => {
  const onResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    onResize()
    window && window.addEventListener("resize", onResize);
    return () => {
      window && window.removeEventListener("resize", onResize);
    }
  }, []);

  return (
    <Wrapper>
      <RnDPage location={location} />
    </Wrapper>
  );
};

export default RnD;
