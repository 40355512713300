import styled, { css } from "styled-components";

import MobileLogoImg from "-!svg-react-loader!../../../assets/images/Logo/mobileLogo.svg";
import {
  slideToTop,
  slideFromRightWithOpacity,
  fadeIn,
  fadeOut,
  slideToRight,
} from "../../../utils/animations";
import { device } from "../../../utils/breakpoints";
import {
  H0,
  H0_LAPTOP,
  H0_MOBILE,
  H2_REGULAR,
  TEXT_BODY_2,
} from "../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

interface BlueReactangleProps {
  column?: string;
  row?: string;
}

interface TitleProps {
  color?: string;
}

export const PageWrapperMobile = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100vh);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const ContentWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileNavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;

export const NavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;

export const LogoMobile = styled(MobileLogoImg)`
  width: 110px;
  height: 24px;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    display: none;
  }
`;

export const TitleWrapperMobile = styled.div`
  width: 100%;
  height: 100vh;
  padding: 160px 24px 96px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleMobile = styled.div`
  ${H0_MOBILE}
  font-size: 200px;
  color: ${(props: TitleProps) => props.color || "#151515"};

  &:nth-of-type(2) {
    margin-left: auto;
  }
`;

export const DescriptionWrapperMobile = styled.div`
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 0 24px 88px 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const DescriptionWithNumberWrapperMobile = styled.div`
  position: relative;

  &:nth-of-type(2) {
    margin-top: 40vh;
    margin-bottom: 24px;
  }
`;

export const NumberMobile = styled.div`
  ${H2_REGULAR}
  margin-bottom: 16px;
  color: #013cff;
`;

export const DescriptionMobile = styled.div`
  ${TEXT_BODY_2}
  color: #151515;
  white-space: pre-line;
`;

export const BackgroundWrapperMobile = styled.div`
  position: absolute;
  width: 80%;
  height: 40vh;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 14% 42% 12% 32%;
  grid-template-rows: 10% 16% 16% 16% 32% 10%;
`;

export const BlueRectangleMobile = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  grid-column: ${(props: BlueReactangleProps) => props.column || "auto / auto"};
  grid-row: ${(props: BlueReactangleProps) => props.row || "auto / auto"};
  background-color: #013cff;
`;

export const TransitionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100vh);
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(5, auto);
  z-index: 1000;
  pointer-events: none;
`;

export const TransitionBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-left: auto;
  background: #ffffff;
  z-index: 1;
`;

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100vh);
  color: #151515;

  .whiteBlock {
    animation: ${(props: PageProps) =>
      props.animation === "next"
        ? css`
            ${fadeIn} 300ms linear
          `
        : css`
            ${slideToRight} 300ms ease-in
          `};
    animation-fill-mode: forwards;
    animation-delay: ${(props: PageProps) =>
      props.animation !== "next" && "0.7s"};

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(11),
    &:nth-child(14),
    &:nth-child(19) {
      animation-delay: ${(props: PageProps) =>
        props.animation !== "next" && "0.1s"};
    }

    &:nth-child(0),
    &:nth-child(1),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(13),
    &:nth-child(16) {
      animation-delay: ${(props: PageProps) =>
        props.animation !== "next" && "0.4s"};
    }
  }
`;

export const StartingPageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 88px 118px 20px 88px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${slideToTop} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    padding: 88px 120px 80px 120px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
  } ;
`;

export const ContentWrapper = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.computer} {
    display: grid;
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(12, 110px);
  } ;
`;

export const TitleWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;

  @media ${device.tablet} {
    position: relative;
    margin: 72px 0 48px 0;
  }

  @media ${device.tabletLandscape} {
    position: relative;
    margin: 24px 0;
  } ;
`;

export const Title = styled.span`
  color: ${(props: TitleProps) => props.color || "#151515"};
  font-size: 200px;
  line-height: 180px;
  font-weight: 800;

  @media ${device.computer} {
    ${H0_LAPTOP}
  }

  @media ${device.desktop} {
    ${H0}
  } ;
`;

export const DescriptionWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.tabletLandscape} {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
  }

  @media ${device.computer} {
    flex: 1;
    justify-content: flex-start;
    grid-column: 5 / 8;
    height: 100vh;
    margin-bottom: 96px;
  }

  @media ${device.desktop} {
    margin-top: 100px;
    grid-column: 6 / 9;
    height: auto;
    margin-bottom: 0;
  } ;
`;

export const DescriptionWithNumberWrapper = styled.div`
  position: relative;
  width: 320px;
  margin: 0;

  &:first-of-type {
    margin-right: auto;
    margin-bottom: 64px;
    transform: translateX(500%);
    animation: ${slideFromRightWithOpacity} 0.6s ease-out;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;
  }

  &:last-of-type {
    margin-left: auto;
    transform: translateX(500%);
    animation: ${slideFromRightWithOpacity} 0.6s ease-out;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  @media ${device.tablet} {
    width: 300px;
    margin: 48px 0;

    &:first-of-type {
      margin-left: 100px;
    }
  }

  @media ${device.tabletLandscape} {
    width: 300px;
    margin: 24px 0;

    &:first-of-type {
      margin-left: auto;
      margin-right: 0;
      grid-column: 1;
      grid-row: 1;
    }

    &:last-of-type {
      margin-left: 0;
      margin-right: auto;
      grid-column: 2;
      grid-row: 2;
    }
  }

  @media ${device.computer} {
    width: 411px;
    display: flex;
    justify-content: flex-start;

    &:last-of-type {
      margin-left: 192px;
    }
  }

  @media ${device.desktop} {
    width: 531px;

    &:first-of-type {
      margin-bottom: 64px;
    }

    &:last-of-type {
      margin-left: 284px;
    }
  }
`;

export const Number = styled.div`
  ${H2_REGULAR}
  position: absolute;
  top: 0;
  left: -88px;
  font-weight: 500;
  color: #013cff;

  &:last-of-type {
    left: -104px;
  }

  @media ${device.computer} {
    position: relative;
    top: auto;
    left: auto;
    margin-right: 24px;
  }
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  color: #151515;
  white-space: pre-line;
`;
